import React, { ReactNode } from 'react';

type DropDownMenuProps = {
	active: boolean;
	children: ReactNode;
	header: ReactNode;
	className?: string;
	onToggle?: () => void;
};

export default function DropDownMenu(props: DropDownMenuProps) {
	const { active, children, header, className, onToggle } = props;

	return (
		<div className={`dropdown-container ${className ?? ''}`}>
			<div className="nav-item" onClick={() => onToggle?.()}>
				{header}
			</div>
			<div className="dropdown">
				<div className={`drop-menu ${active ? 'active' : ''}`}>
					<div className="dropdown-content">{children}</div>
				</div>
			</div>
		</div>
	);
}
