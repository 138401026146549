import React from 'react';
import Link from 'next/link';
import { IconUserCircle } from '@tabler/icons-react';

type AccountLinkProps = {
	className?: string;
	isLoading: boolean;
};

export default function AccountLink(props: AccountLinkProps) {
	const { className, isLoading } = props;

	return (
		<Link className={`auth-links account-link ${className ?? ''}`} href="/account">
			<IconUserCircle size={24} />
			<span>{isLoading ? 'Loading...' : 'my account'}</span>
		</Link>
	);
}
