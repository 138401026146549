import Button from '@dr-pam/common-components/Components/Form/Button';
import Form from '@dr-pam/common-components/Components/Form/Form';
import FormControl from '@dr-pam/common-components/Components/Form/FormControl';
import TextInput from '@dr-pam/common-components/Components/Form/TextInput';
import { isNotEmpty, useForm } from '@mantine/form';
import { IconArrowBackUp, IconArrowRight, IconSearch } from '@tabler/icons-react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';

type SearchFormProps = {
	onClose: () => void;
};

export default function SearchForm(props: SearchFormProps) {
	const { onClose } = props;

	const [isSearching, setIsSearching] = useState(false);

	const pathname = usePathname();
	const params = useSearchParams();
	const router = useRouter();
	const existingSearchQuery = params.get('q');

	const form = useForm<SearchFormType>({
		initialValues: {
			query: '',
		},
		validate: {
			query: isNotEmpty('Search query is required'),
		},
	});

	useEffect(() => {
		setIsSearching(false);
	}, [pathname, existingSearchQuery]);

	const onFormSubmit = async (values: SearchFormType) => {
		const { query } = values;
		if (query.trim() !== existingSearchQuery) {
			router.push(`/search?q=${encodeURIComponent(query)}`);
			setIsSearching(true);
		}
	};

	return (
		<Form form={form} onSubmit={onFormSubmit} className="nav-form search-form">
			<div className="header mobile-only">
				<div className="back-button" onClick={onClose}>
					<IconArrowBackUp size={32} />
				</div>
			</div>
			<div className="nav-form-container">
				<h1 className="mobile-only title">Search programs</h1>
				<FormControl name="query" className="search-bar">
					<IconSearch className="search-icon" />
					<TextInput placeholder="search for articles" className="search-input" />
				</FormControl>
				<Button
					type="submit"
					isLoading={isSearching}
					className="solid outline dark reverse search-button"
					disabled={!form.values.query.trim() || isSearching}
				>
					<div className="desktop-only">
						<IconArrowRight size={32} />
					</div>
					<span className="mobile-only">Search</span>
				</Button>
			</div>
		</Form>
	);
}

export type SearchFormType = {
	query: string;
};
