import React, { ReactNode } from 'react';
import DropDownTextHeader from './DropDownTextHeader';
import DropDownMenu from './DropDownMenu';

type DropDownMenuProps = {
	active: boolean;
	children: ReactNode;
	title: string;
	onToggle: () => void;
};

export default function DropDownTextMenu(props: DropDownMenuProps) {
	const { active, children, title, onToggle } = props;

	return (
		<DropDownMenu
			active={active}
			header={
				<button className="drop-btn" onClick={onToggle}>
					<DropDownTextHeader title={title} />
				</button>
			}
		>
			{children}
		</DropDownMenu>
	);
}
