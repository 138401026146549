'use client';
import React from 'react';
import Button from '@dr-pam/common-components/Components/Form/Button';
import Form from '@dr-pam/common-components/Components/Form/Form';
import FormControl from '@dr-pam/common-components/Components/Form/FormControl';
import TextInput from '@dr-pam/common-components/Components/Form/TextInput';
import useNewsletterSignup from '@dr-pam/common-components/Hooks/useNewsletterSignup';

export default function SubscribeForm() {
	const { form, handleFormSubmit, isLoading } = useNewsletterSignup();
	return (
		<Form form={form} className="subscribe" onSubmit={handleFormSubmit}>
			<h3>Get sleep tips and Possums updates straight to your inbox</h3>
			<p>
				Receive tips, updates, offers and more from Possums. We respect your privacy, and we&apos;ll never spam
				you. Unsubscribe anytime.
			</p>
			<div className="cta">
				<FormControl name="email" label="Email" disabled={isLoading}>
					<TextInput type="email" placeholder="Email" autoComplete="email" />
				</FormControl>
				<Button type="submit" className="solid dark outline reverse" disabled={isLoading}>
					Sign Up Now
				</Button>
			</div>
		</Form>
	);
}
