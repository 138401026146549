'use client';

import React from 'react';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import SignUpButton from '../auth/SignUpButton';
import AccountLink from './navbar/auth/AccountLink';
import LoginLink from './navbar/auth/LoginLink';
import { SubMenu } from './NavBar';

export type AuthLinkProps = {
	activeMenu: SubMenu;
	className?: string;
	onClick?: (item: SubMenu) => void;
	onCloseLogin: () => void;
};

export default function AuthLinks(props: AuthLinkProps) {
	const { activeMenu, className, onClick, onCloseLogin } = props;
	const { user, isLoading } = useAuthenticatedUser();

	if (user) {
		return <AccountLink isLoading={isLoading} className={`nav-item ${className ?? ''}`} />;
	}

	return (
		<>
			<LoginLink
				active={activeMenu === SubMenu.LOGIN}
				className={className}
				isLoading={isLoading}
				onClick={() => onClick?.(SubMenu.LOGIN)}
				onClose={onCloseLogin}
			/>
			<SignUpButton className={`auth-links signup-link nav-item ${className ?? ''}`}>
				<span>{isLoading ? 'Loading...' : 'sign up'}</span>
			</SignUpButton>
		</>
	);
}
