'use client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { IconMenu2, IconX, IconSearch } from '@tabler/icons-react';
import AuthLinks from './AuthLinks';
import GoogleTagManager from '@dr-pam/common-components/Components/Page/GoogleTagManager';
import { usePathname } from 'next/navigation';
import SearchForm from '../search/SearchForm';
import Image from 'next/image';
import DropDownTextMenu from './navbar/DropDownTextMenu';
import DropDownMenu from './navbar/DropDownMenu';

export type NavBarProps = {
	gtmId: string;
};

export enum SubMenu {
	NONE = '',
	BROWSE_PROGRAM = 'browse-program',
	ABOUT = 'about',
	LOGIN = 'login',
	ACCOUNT = 'account',
	SEARCH = 'search',
}

export default function NavBar(props: NavBarProps) {
	const { gtmId } = props;
	const [showNavbar, setShowNavbar] = useState(false);
	const [activeSubMenu, setActiveSubMenu] = useState<SubMenu>(SubMenu.NONE);

	const menuRef = useRef<null | HTMLDivElement>(null);

	const pathname = usePathname();

	const toggleSubMenu = (item: SubMenu) => {
		setActiveSubMenu(activeSubMenu === item ? SubMenu.NONE : item);
	};

	const toggleMenu = () => {
		setShowNavbar(!showNavbar);
		if (!showNavbar) {
			document.body.classList.add('menu-open');
		} else {
			document.body.classList.remove('menu-open');
		}
	};
	const closeMenu = () => {
		setShowNavbar(false);
		document.body.classList.remove('menu-open');
	};

	useEffect(() => {
		closeMenu();
	}, [pathname]);

	const closeSubMenu = () => {
		setActiveSubMenu(SubMenu.NONE);
	};

	const closeNavbar = useCallback(() => {
		setActiveSubMenu(SubMenu.NONE);
		closeMenu();
	}, []);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				closeNavbar();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [closeNavbar]);

	return (
		<header className="navbar dark-bg" ref={menuRef}>
			<div className="navbar-container">
				<GoogleTagManager gtmId={gtmId} />
				<Link className="logo-container" href="/">
					<Image
						src="/images/logos/possums_logo-dark_bg.png"
						className="icon-logo"
						alt="Logo - The Possums baby and toddler sleep program."
						width={0}
						height={0}
						unoptimized
					/>
				</Link>
				<nav>
					<div className="hamburger-menu" onClick={toggleMenu}>
						{!showNavbar ? <IconMenu2 size={32} /> : <IconX size={32} />}
					</div>
					<div className={`menu-container ${showNavbar ? 'show' : ''}`}>
						<div className="menu-links">
							<DropDownTextMenu
								title="browse program"
								active={activeSubMenu === SubMenu.BROWSE_PROGRAM}
								onToggle={() => toggleSubMenu(SubMenu.BROWSE_PROGRAM)}
							>
								<Link className="nav-link" href="/browse-topics" onClick={closeNavbar}>
									full programs
								</Link>
								<Link className="nav-link" href="/audio" onClick={closeNavbar}>
									audio program
								</Link>
							</DropDownTextMenu>
							<DropDownTextMenu
								title="about"
								active={activeSubMenu === SubMenu.ABOUT}
								onToggle={() => toggleSubMenu(SubMenu.ABOUT)}
							>
								<Link className="nav-link" href="/#about" onClick={closeNavbar}>
									what is the possums sleep program?
								</Link>
								<Link className="nav-link" href="/#building" onClick={closeNavbar}>
									building this program from this science
								</Link>
								<Link className="nav-link" href="/#research" onClick={closeNavbar}>
									research that shows it works
								</Link>
								<Link className="nav-link" href="/#testimonials" onClick={closeNavbar}>
									testimonials from parents
								</Link>
							</DropDownTextMenu>
							<Link href="/speak-to-an-ndc-practitioner" className="nav-item">
								speak to dr pam
							</Link>
						</div>
						<div className="menu-links">
							<div className="mobile-spacer hidden-on-desktop"></div>
							<AuthLinks
								activeMenu={activeSubMenu}
								onClick={(item) => {
									toggleSubMenu(item);
								}}
								onCloseLogin={closeSubMenu}
							/>

							<div className="mobile-spacer hidden-on-desktop"></div>
							<DropDownMenu
								active={activeSubMenu === SubMenu.SEARCH}
								className="toggle-search"
								onToggle={() => toggleSubMenu(SubMenu.SEARCH)}
								header={
									<>
										<IconSearch />
										<div className="mobile-only">search</div>
									</>
								}
							>
								<SearchForm onClose={closeSubMenu} />
							</DropDownMenu>
						</div>
					</div>
				</nav>
			</div>
		</header>
	);
}
