'use client';
import React, { ReactNode, useRef, useState } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import Form from '@dr-pam/common-components/Components/Form/Form';
import FormControl from '@dr-pam/common-components/Components/Form/FormControl';
import TextInput from '@dr-pam/common-components/Components/Form/TextInput';
import Button from '@dr-pam/common-components/Components/Form/Button';
import { IconArrowBackUp } from '@tabler/icons-react';
import { useSearchParams } from 'next/navigation';
import { useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type LoginFormProps = {
	className?: string;
	children?: ReactNode;
	email?: string;
	onClose: () => void;
};

export default function LoginForm(props: LoginFormProps) {
	const { className, children, email, onClose } = props;

	const { isLoading, addLoader, removeLoader } = useLoadTracker();
	const [error, setError] = useState<ReactNode | null>(null);

	const form = useForm<LoginFormType>({
		initialValues: {
			email: email ?? '',
			password: '',
		},
		validate: {
			email: isNotEmpty('Email is required'),
			password: isNotEmpty('Password is required'),
		},
	});

	const passwordRef = useRef<HTMLInputElement>(null);

	const searchParams = useSearchParams();
	const authService = useAuthService();

	const onLoginRequested = async (form: LoginFormType): Promise<boolean> => {
		try {
			const loggedInUser = await authService.signIn(form.email, form.password);
			if (!loggedInUser) {
				NotificationUtils.showError(
					new Error('Please check your credentials and try again.'),
					'Incorrect email and/or password.',
				);
				return false;
			}

			setTimeout(() => {
				// Use a full page navigation here rather than a router push so that
				// it forces the page to reload and clear any cached data.
				const redirectUrl = searchParams.get('r') ?? '/';
				window.location.href = redirectUrl;
			}, 1000); // Small delay to allow the user to see the success message

			NotificationUtils.showSuccess('Redirecting you now...', 'Login successful', 3000);

			return true;
		} catch (error) {
			NotificationUtils.showError(error as Error, 'Login failed');
			return false;
		}
	};

	const onFormSubmit = async (values: LoginFormType) => {
		const loader = addLoader();
		try {
			setError(null);
			const result = await onLoginRequested(values);
			if (!result) {
				form.setFieldValue('password', '');
				setTimeout(() => {
					passwordRef.current?.focus();
				}, 100);
				removeLoader(loader);
			}
			// If we get here, the login was successful.
			// DON'T remove the loader, because we're navigating away and it will be removed by the page change.
		} catch (err) {
			debugger;
			if (err instanceof Error) {
				setError(err.message);
			} else if (err) {
				setError(`${err}`);
			} else {
				setError('Unknown error');
			}
			removeLoader(loader);
		}
	};

	return (
		<div className={`LoginForm ${className ?? ''}`}>
			<Form form={form} onSubmit={onFormSubmit} disabled={isLoading} className="nav-form login-form">
				<div className="header mobile-only">
					<div className="back-button" onClick={() => onClose()}>
						<IconArrowBackUp size={32} />
					</div>
				</div>
				<div className="nav-form-container">
					<h1 className="mobile-only title">Welcome back!</h1>
					<FormControl name="email" label="Email">
						<TextInput
							type="email"
							placeholder="Username or Email"
							autoComplete="email"
							className="form-input"
						/>
					</FormControl>
					<FormControl name="password" label="Password">
						<TextInput
							ref={passwordRef}
							type="password"
							placeholder="Password"
							autoComplete="current-password"
							className="form-input"
						/>
					</FormControl>
					<Button type="submit" isLoading={isLoading} className="solid dark outline reverse ">
						Login
					</Button>
					{error && <div className="error">{error}</div>}
					{children}
				</div>
			</Form>
		</div>
	);
}

export type LoginFormType = {
	email: string;
	password: string;
};
