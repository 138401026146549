import Link from 'next/link';
import React, { MouseEvent } from 'react';
import Image from 'next/image';
import DropDownMenu from '../DropDownMenu';
import LoginForm from '../../../auth/LoginForm';

type LoginLinkProps = {
	active: boolean;
	className?: string;
	isLoading: boolean;
	onClick: () => void;
	onClose: () => void;
};

export default function LoginLink(props: LoginLinkProps) {
	const { active, className, isLoading, onClick, onClose } = props;

	return (
		<DropDownMenu
			className={`login-link ${className}`}
			active={active}
			onToggle={onClick}
			header={
				<>
					<Image
						src="/images/icons/login.svg"
						width={20}
						height={20}
						alt="login-icon"
						className="mobile-only"
					/>
					<span>{isLoading ? 'Loading...' : 'login'}</span>
				</>
			}
		>
			<LoginForm onClose={onClose}>
				<Link className="forgot-password" href="/forgot-password" onClick={onClose}>
					forgot password
				</Link>
			</LoginForm>
		</DropDownMenu>
	);
}
