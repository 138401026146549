import React from 'react';
import { IconChevronDown } from '@tabler/icons-react';

type DropDownTextHeader = {
	title: string;
};

export default function DropDownTextHeader(props: DropDownTextHeader) {
	const { title } = props;
	return (
		<>
			{title}
			<IconChevronDown className="carrot" size={16} />
		</>
	);
}
